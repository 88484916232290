var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"wh-container",attrs:{"id":"tiss-manage-guide-items"}},[_c('div',{staticClass:"nav"},[_c('Breadcrumbs',{attrs:{"options":[
        {
          label: 'Faturamento TISS',
          onClick: () => this.$router.push('/faturamento-sus/dashboard')
        },
        {
          label: 'Lotes enviados',
          onClick: () => this.$router.push('/faturamento-sus/lotes-enviados')
        },
        { 
          label: 'Gerenciar guias',
          onClick: () => this.$router.push({ name: 'tiss-manage-guides', params: { batchId: _vm.$route.params.batchId }})
        },
        { 
          label: 'Guias'
        }
      ]}}),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.bulkUpdateTissGuideItems}},[_vm._v("Salvar")])],1),_c('b-container',{staticClass:"guides-content",attrs:{"fluid":""}},[_c('GuidesHeader',{attrs:{"type":"guideItem","id":_vm.$route.params.guideId,"loading":_vm.loading,"disabledStatus":_vm.selectedTissGuideItems.length === 0},on:{"update":_vm.updateStatuses,"reload":_vm.getTissGuideItems}}),_c('GuideItemsFilter',{on:{"changeFilter":_vm.changeFilter}}),_c('GuidesTableItem',{attrs:{"tissGuideItems":_vm.tissGuideItems,"selectedTissGuideItems":_vm.selectedTissGuideItems,"handleSelectRow":_vm.handleSelectRow,"selectAll":_vm.selectAll,"unSelectAll":_vm.unSelectAll}}),_c('ManageGuideItemsSidebar',{on:{"update":_vm.updateGuideItems,"save":_vm.bulkUpdateTissGuideItems}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }