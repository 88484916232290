<template>
  <section id="tiss-manage-guide-items" class="wh-container">
    <div class="nav">
      <Breadcrumbs
        :options="[
          {
            label: 'Faturamento TISS',
            onClick: () => this.$router.push('/faturamento-sus/dashboard')
          },
          {
            label: 'Lotes enviados',
            onClick: () => this.$router.push('/faturamento-sus/lotes-enviados')
          },
          { 
            label: 'Gerenciar guias',
            onClick: () => this.$router.push({ name: 'tiss-manage-guides', params: { batchId: $route.params.batchId }})
          },
          { 
            label: 'Guias'
          }
        ]"
      />
      <b-button variant="primary" @click="bulkUpdateTissGuideItems">Salvar</b-button>
    </div>

    <b-container class="guides-content" fluid>
      <GuidesHeader
        type="guideItem"
        :id="$route.params.guideId"
        :loading="loading"
        :disabledStatus="selectedTissGuideItems.length === 0"
        @update="updateStatuses"
        @reload="getTissGuideItems"
      />
      <GuideItemsFilter @changeFilter="changeFilter" />
      <GuidesTableItem 
        :tissGuideItems="tissGuideItems" 
        :selectedTissGuideItems="selectedTissGuideItems"
        :handleSelectRow="handleSelectRow"
        :selectAll="selectAll"
        :unSelectAll="unSelectAll"
      />
      <ManageGuideItemsSidebar 
        @update="updateGuideItems" 
        @save="bulkUpdateTissGuideItems"
      />
    </b-container>
  </section>
</template>
<script>
import api from '../api'
import { TissGuideItem } from '@/modules/tiss/manageGuides/utils/statuses'
import { onChangeStatus } from '@/modules/tiss/manageGuides/utils/guideHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'manage-guide-items',
  components: {
    Breadcrumbs: () => import('@/components/General/Breadcrumbs'),
    GuidesHeader: () => import('@/modules/tiss/manageGuides/components/GuidesHeader'),
    GuideItemsFilter: () => import('@/modules/tiss/manageGuides/components/GuideItemsFilter'),
    GuidesTableItem: () => import('@/modules/tiss/manageGuides/components/GuidesTableItem'),
    ManageGuideItemsSidebar: () => import('@/modules/tiss/manageGuides/sidebar/ManageGuideItemsSidebar'),
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      loading: false,
      tissGuideItems: [],
      selectedTissGuideItems: [],
      filters: {},
    }
  },
  async mounted() {
    await this.getTissGuideItems()
  },
  methods: {
    async getTissGuideItems() {
      this.loading = true
      try {
        const { data } = await api.getTissGuideItems(this.$route.params.guideId, this.filters)
        this.tissGuideItems = data
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.loading = false
      } 
    },
    async bulkUpdateTissGuideItems() {
      if (this.tissGuideItems.some(item => item.status === null)) return this.$toast.warning('Existem itens sem uma situação definida')
      if (this.tissGuideItems.some(item => item.status === TissGuideItem.STATUS_ITEM_PARTIALLY_PAID && item.value_paid <= 0)) return this.$toast.warning('Existem itens com a situação "Pago parcial" e valor a receber igual ou menor que zero')

      this.loading = true
      try {
        const data = this.tissGuideItems.map((item) => ({
            id: item.id,
            status: item.status,
            value_paid: item.value_paid,
            glossed_value: item.glossed_value,
            guide_id: item.guide_id,
            guide_type: item.guide_type,
        }))
        await api.bulkUpdateTissGuideItems(this.$route.params.guideId, data)
        this.$toast.success('Itens atualizados com sucesso')
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.loading = false
      }
    },  
    handleSelectRow(checked, tissGuideItem) {
      if (!checked) {
        this.selectedTissGuideItems = this.selectedTissGuideItems.filter(tissGuideItemId => tissGuideItemId !== tissGuideItem.id)
        return
      }
      if (this.selectedTissGuideItems.includes(tissGuideItem.id)) return
      this.selectedTissGuideItems.push(tissGuideItem.id)
    },
    selectAll() {
      this.selectedTissGuideItems = this.tissGuideItems.map(tissGuideItem => tissGuideItem.id)
    },
    unSelectAll() {
      this.selectedTissGuideItems = []
    },
    updateStatuses(status) {
      this.tissGuideItems.forEach((tissGuideItem) => {
        if (this.selectedTissGuideItems.includes(tissGuideItem.id)) {
          tissGuideItem.status = status
          onChangeStatus(tissGuideItem, status)
        }
      })
    },
    updateGuideItems(status) {
      this.tissGuideItems.forEach((tissGuideItem) => {
        tissGuideItem.status = status
        onChangeStatus(tissGuideItem, status)
      })
    },
    changeFilter(filters) {
      this.filters = filters
    }
  },
  watch: {
    filters: {
      handler: function () {
        this.getTissGuideItems()
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
#tiss-manage-guide-items {
  padding: 25px;

  .wh-container {
    height: 100%;
  }

  .nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .breadcrumbs-wrapper {
    width: inherit;
    padding: 0;
  }

  .guides-content {
    margin-top: 20px;
    padding: 20px 25px;
    border-radius: 8px;
    background-color: var(--neutral-000);
  }

  .filters {
    display: grid;
    grid-template-columns: 1fr repeat(1, 300px);
    grid-column-gap: 20px;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  .table {
    tr:first-child {
      width: 15px;
    }
  }
}
</style>